import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './views/layouts/AppLayout.vue'
import router from './router'

import 'bootstrap'

import './assets/scss/app.scss'
import axios from 'axios'
import _ from 'lodash'
import i18n from './i18n'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import stickySpinner from './mixins/stickySpinner'

import { store } from '@customer-apps/styleguide'

let headers = {}
if (store?.state?.auth?.access) {
  headers = { Authorization: 'Bearer ' + store.state.auth.access }
}

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: headers,
  timeout: 60000
})

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecyle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      })
    }
  },
  handleInstance (app) {
    app.use(router)
    app.use(VueLoading)
    app.use(i18n)
    app.provide('$axios', apiAxios)
    app.mixin(stickySpinner)
    app.config.globalProperties.$_ = _
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
