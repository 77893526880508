<template>
  <div id="app-cadastre">
    <main role="main" class="wrapper">
      <nav id="sidebar">
        <ul class="list-unstyled components list-group">
          <li class="li-mobile-width">
            <router-link :to="{ name: 'Dashboard' }" active-class="active"
              class="list-group-item list-group-item-action">
              <img class="img-fluid navbar-icon" src="@/assets/img/view_grid-white-24px.svg">
            </router-link>
          </li>
          <li class="li-mobile-midwidth">
            <router-link :to="{ name: 'Search' }" active-class="active" class="list-group-item list-group-item-action">
              <img class="img-fluid navbar-icon" src="@/assets/img/search-white-24px.svg">
            </router-link>
          </li>
          <!-- <li class="li-mobile-width">
          <router-link :to="{ name: 'Graphics' }" active-class="active" class="list-group-item list-group-item-action">
            <img class="img-fluid navbar-icon" src="@/assets/img/show_chart-white-24px.svg">
          </router-link>
        </li> -->
        </ul>
      </nav>
      <router-view class="tab-content" />
    </main>
  </div>
</template>

<script>

const totalsData = [
  {
    name: 'sites',
    classCount: 'clr-purple',
    endpoint: '/sites/count',
    count: null,
    searchSection: {
      section: 'site'
    },
    showDashboard: true,
  },
  {
    name: 'routers',
    classCount: 'clr-green',
    endpoint: '/equipments/count',
    apiParams: { type: 'Router' },
    count: null,
    searchSection: {
      section: 'equipment',
      formData: { class: 'Router' }
    },
    showDashboard: true,
  },
  {
    name: 'switches',
    classCount: 'clr-green',
    endpoint: '/equipments/count',
    apiParams: { type: 'Network Switch' },
    count: null,
    searchSection: {
      section: 'equipment',
      formData: { class: 'Network Switch' }
    },
    showDashboard: true,
  },
  {
    name: 'wireless',
    classCount: 'clr-green',
    endpoint: '/equipments/count',
    apiParams: { type: 'Wireless' },
    count: null,
    searchSection: {
      section: 'equipment',
      formData: { class: 'Wireless' }
    },
    showDashboard: true,
  },
  {
    name: 'telefones hcs',
    classCount: 'clr-yellow',
    endpoint: '/hcsphones/count',
    count: null,
    searchSection: {
      section: 'hcsphone'
    },
    showDashboard: true,
  },
  {
    name: 'acessos / conectividades',
    classCount: 'clr-red',
    endpoint: '/accesses/count',
    count: null,
    searchSection: {
      section: 'access'
    },
    showDashboard: true,
  },
  {
    name: 'Ip Lan',
    classCount: 'clr-red',
    endpoint: '/lanips/count',
    count: null,
    searchSection: {
      section: 'lanips'
    },
    showDashboard: false,
  },
  {
    name: 'Linhas de rede',
    classCount: 'clr-red',
    endpoint: '/networklines/count',
    count: null,
    searchSection: {
      section: 'network'
    },
    showDashboard: false,
  }

]

export default {
  inject: ['$axios'],
  data() {
    return {
      totalsData: totalsData
    }
  },
  created() {
    this.getCounts()
  },
  mounted() {
    window.addEventListener('scroll', this.stickySpinner)
    window.addEventListener('resize', this.stickySpinner)
  },
  unmounted() {
    window.removeEventListener('scroll', this.stickySpinner)
    window.removeEventListener('resize', this.stickySpinner)
  },
  methods: {
    getCounts() {
      this.totalsData.filter((totalData) => {
        let data = {}

        if (!totalData.endpoint) {
          return
        }

        if (totalData.apiParams) {
          data = {
            params: totalData.apiParams
          }
        }
        this.$axios.get(totalData.endpoint, data).then(({ data }) => {
          totalData.count = data
        })
      })
    },
  },
  provide: {
    totalsData: totalsData,
  }
}
</script>

<style lang="scss">
#app-cadastre {
  .vld-container .vld-overlay .vld-icon {
    position: absolute;
    /* position: sticky */
    top: calc(50vh - 100px);
  }

  .row {
    width: auto;
  }
}
</style>
