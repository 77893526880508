import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/app/cadastro',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/pages/Dashboard.vue')
  },
  {
    path: '/app/cadastro/search/:section?',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "search" */ '../views/pages/Search.vue')
  },
  {
    path: '/app/cadastro/site-details/:id',
    name: 'SiteDetails',
    component: () => import(/* webpackChunkName: "sitedetails" */ '../views/pages/SiteDetails.vue')
  },
  {
    path: '/app/cadastro/equipment-details/:id',
    name: 'EquipmentDetails',
    component: () => import(/* webpackChunkName: "equipmentdetails" */ '../views/pages/EquipmentDetails.vue')
  },
  {
    path: '/app/cadastro/access-details/:id',
    name: 'AccessDetails',
    component: () => import(/* webpackChunkName: "accessdetails" */ '../views/pages/AccessDetails.vue')
  }
  /* {
    path: '/app/cadastro/graphics',
    name: 'Graphics',
    component: () => import(/* webpackChunkName: "graphicsdetails" */ /* '../views/pages/Graphics.vue')
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
