export default {
  methods: {
    stickySpinner () {
      const spinner = document.querySelector('.vld-container .vld-overlay .vld-icon')

      if (spinner) {
        spinner.style.transform = 'translateY(' + window.scrollY + 'px)'
      }
    }
  }
}
